import React, {useState, useEffect} from 'react';

function Step2({ Next, Prev, changeWorth, currentValues }) {

  useEffect(() => {
    var element = document.getElementById("special");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
  }, []);

  const [highLightedButton, setHighLightedButton] = useState(currentValues.Worth)

  const highLightButton =(value)=>{
    if(highLightedButton === value){
      return "HighlightedButtonStyle"
    }else{
      return "formButtons"
    }
  }

  const combinedClick =(value)=>{
    setHighLightedButton(value)
    changeWorth(value)
  }

  return (
    <div className="formContainer">
      <div className="textContainer">
        <h1 className="formTitle">Estimez la Valeur de votre Maison. <br/>Gratuitement et sans Engagement.</h1>
      </div>
      <hr/>
      <div className="formBox" id="special">
        <h2>2. Valeur Approximative du Bien (selon vous)</h2>
        <div className="formContent">
          <button
            className={highLightButton("Less Than 250,000")}
            value="Less Than 250,000"
            onClick={() => combinedClick('Less Than 250,000')}
          >
            Moins de 250'000
          </button>
          <button
            className={highLightButton("250,000 - 500,000")}
            value="250,000 - 500,000"
            onClick={() => combinedClick('250,000 - 500,000')}
          >
            250'000 - 500'000
          </button>
          <button
            className={highLightButton("500,000 - 1,000,000")}
            value="500,000 - 1,000,000"
            onClick={() => combinedClick('500,000 - 1,000,000')}
          >
            500'000 - 1'000'000
          </button>
          <button
            className={highLightButton("1,000,000 - 2,000,000")}
            value="1,000,000 - 2,000,000"
            onClick={() => combinedClick('1,000,000 - 2,000,000')}
          >
            1'000'000 - 2'000'000
          </button>
          <button
            className={highLightButton("More than 2,000,000")}
            value="More than 2,000,000"
            onClick={() => combinedClick('More than 2,000,000')}
          >
            Plus de 2'000'000
          </button>
          <button className={highLightButton("Not Sure")} value="Not Sure" onClick={() => combinedClick('Not Sure')}>
            Pas Sûr
          </button>
        </div>
        <div className="containerWidth">
          <div className="buttonContainer">
            <button className="prevButton" onClick={Prev}>
              Précédent
            </button>
            <button className="nextButton" onClick={Next}>
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step2;
