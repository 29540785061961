import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MultiStepForm } from './Components/index.js';

function App() {
  return (
    <div className="App">
      {/* <div className="logoContainer"><img className="logo" src={require("./Assets/yesimmo.png")}/></div> */}
      <MultiStepForm />
    </div>
  );
}

export default App;
