import React, { useState, useEffect } from 'react';

function Step3({ Next, Prev, changeWhen, currentValues }) {

  useEffect(() => {
    var element = document.getElementById("special");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
  }, []);
  const [highLightedButton, setHighLightedButton] = useState(currentValues.When)

  const highLightButton =(value)=>{
    if(highLightedButton === value){
      return "HighlightedButtonStyle"
    }else{
      return "formButtons"
    }
  }

  const combinedClick =(value)=>{
    setHighLightedButton(value)
    changeWhen(value)
  }
  return (
    <div className="formContainer">
      <div className="textContainer">
        <h1 className="formTitle">Estimez la Valeur de votre Maison. <br/>Gratuitement et sans Engagement.</h1>
      </div>
      <hr/>
      <div className="formBox" id="special">
        <h2>3. Voulez-Vous la Vendre?</h2>
        <div className="formContent">
          <button
            className={highLightButton("As Soon As Possible")}
            value="As Soon As Possible"
            onClick={() => combinedClick("As Soon As Possible")}
          >
            Dès Que Possible
          </button>
          <button className={highLightButton("1-3 months")} value="1-3 months" onClick={() => combinedClick("1-3 months")}>
            1-3 Mois
          </button>
          <button className={highLightButton("3-6 months")} value="3-6 months" onClick={() => combinedClick("3-6 months")}>
            3-6 Mois
          </button>
          <button className={highLightButton("6-12 months")} value="6-12 months" onClick={() => combinedClick('6-12 months')}>
            6-12 Mois
          </button>
          <button className={highLightButton("Not Now")} value="Not Now" onClick={() => combinedClick('Not Now')}>
            Pas Maintenant
          </button>
          <button className={highLightButton("Not Sure")} value="Not Sure" onClick={() => combinedClick('Not Sure')}>
            Pas Sûr
          </button>
        </div>
        <div className="containerWidth">
          <div className="buttonContainer">
            <button className="prevButton" onClick={Prev}>
              Précédent
            </button>
            <button className="nextButton" onClick={Next}>
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step3;
