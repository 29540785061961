import React from 'react';

function ThankYou(){
    return(
        <div className="formContainer">
            <div className="formBox">
                <h1 className="ThankTexts">Merci, nous vous contactons très prochainement !</h1>
            </div>
        </div>
    )
}

export default ThankYou;