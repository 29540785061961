import React, { useState, useEffect } from 'react';

const addressObject = {
  Address: null,
  City: null,
  State: null,
  Zipcode: null,
  Agent: false,
};

function Step5({ Next, Prev, changeAddress, currentValues }) {

  useEffect(() => {
    var element = document.getElementById("special");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
  }, []);
  const [address, setAddress] = useState(addressObject);

  const onChangeAddress =(address)=>{
    setAddress(address)
    changeAddress(address);
  }

  return (
    <div className="formContainer">
      <div className="textContainer">
        <h1 className="formTitle">Estimez la Valeur de votre Maison. <br/>Gratuitement et sans Engagement.</h1>
      </div>
      <hr/>
      <form className="formBox" onSubmit={(e)=>{e.preventDefault();Next();}} id="special">
        <h2>5. Adresse du Bien</h2>
        <div className="formAddressContent">
          <div className="formAddressRow">
            <div className="HomeAddressInputContainer">
              <h4>Rue et Numéro</h4>
              <input
                className="HomeAddressInput"
                placeholder="Rue et Numéro"
                onChange={e => onChangeAddress({ ...address, Address: e.target.value })}
                value={currentValues.Address ?  currentValues.Address : ""}
                required
              ></input>
            </div>
            <div className="cityInputContainer">
              <h4>Ville / Localité</h4>
              <input 
                className="HomeAddressInput" 
                placeholder="Ville / Localité" 
                onChange={e => onChangeAddress({ ...address, City: e.target.value })} 
                value={currentValues.City ?  currentValues.City : ""}
                required></input>
            </div>
          </div>
          <div className="formAddressRow">
            <div className="stateInputContainer">
              <h4>Canton</h4>
              <input 
                className="stateInput" 
                placeholder="Canton" 
                onChange={e => onChangeAddress({ ...address, State: e.target.value })} 
                value={currentValues.State ?  currentValues.State : ""}
                required></input>
            </div>
            <div className="zipInputContainer">
              <h4>Code Postal</h4>
              <input 
                className="zipInput" 
                placeholder="Code Postal" 
                onChange={e => onChangeAddress({ ...address, Zipcode: e.target.value })} 
                value={currentValues.Zipcode ?  currentValues.Zipcode : ""}
                required></input>
            </div>
            {/* <div className="agentInputContainer">
              <h5>Propterty Listed with Agent?</h5>
              <label className="switch" onChange={e => onChangeAddress({ ...address, Agent: !address.Agent })}>
                <input className="HomeAddressInput" type="checkbox"/>
                <span className="slider round"></span>
              </label>
            </div> */}
          </div>
        </div>
        <div className="containerWidth">
          <div className="buttonContainer">
            <button className="prevButton" onClick={Prev}>
              Précédent
            </button>
            {/* <input className="nextButton" type="submit" value="Suivant" > </input> */}
            <button className="nextButton" onClick={Next}>
              Suivant
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Step5;
