import React, { useState, UseEffect } from 'react';

function Step1({ Next, changeProperty, currentValues }) {

  const [highLightedButton, setHighLightedButton] = useState(currentValues.Property)

  const highLightButton =(value)=>{
    if(highLightedButton === value){
      return "HighlightedButtonStyle"
    }else{
      return "formButtons"
    }
  }

  const combinedClick =(value)=>{
    setHighLightedButton(value)
    changeProperty(value)
  }

  return (
    <div className="formContainer">
      <div className="textContainer">
      <h1 className="formTitle">Estimez la Valeur de votre Maison. <br/>Gratuitement et sans Engagement.</h1>
      </div>
      <hr/>
      <div className="formBox">
        <h2>1. Type de Bien</h2>
        <div className="formContent">
          <button className={highLightButton("Single Family House")} value="Single Family House" onClick={() => combinedClick('Single Family House')}>
            Maison Individuelle
          </button>
          <button className={highLightButton("Town House")} value="Town House" onClick={() => combinedClick('Town House')}>
            Maison Contiguë
          </button>
          <button className={highLightButton("Condo")} value="Condo" onClick={() => combinedClick('Condo')}>
            Appartement
          </button>
          <button className={highLightButton("Land")} value="Land" onClick={() => combinedClick('Land')}>
            Terrain
          </button>
          <button className={highLightButton("Commercial")} value="Commercial" onClick={() => combinedClick('Commercial')}>
            Bureau / Commercial
          </button>
          <button className={highLightButton("Not Sure")} value="Not Sure" onClick={() => combinedClick('Not Sure')}>
            Pas Sûr
          </button>
        </div>
        <div className="containerWidth">
          <div className="buttonContainer">
            <button className="nextButton" onClick={Next}>
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1;
