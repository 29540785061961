import React, { useState } from 'react';
import { Step1, Step2, Step3, Step4, Step5, Step6, ThankYou } from './';

/* global gapi */
const defaultData = {
  Property: 'Single Family House',
  Worth: 'Less Than 250,000',
  When: 'As Soon As Possible',
  Beds: "1",
  Bathrooms: "1",
  Address: null,
  City: null,
  State: null,
  Zipcode: null,
  Agent: false,
  FirstName: null,
  LastName: null,
  Email: null,
  PhoneNumber: null,
};

function MultiStepForm() {
  const [formData, setFormData] = useState(defaultData);
  const [stepNumber, setStepNumber] = useState(1);

  const add = () => {
    setStepNumber(stepNumber + 1);
  };

  const subtract = () => {
    setStepNumber(stepNumber - 1);
  };

  const onChangeProperty = value => {
    setFormData({ ...formData, Property: value });
  };

  const onChangeWorth = value => {
    setFormData({ ...formData, Worth: value });
  };

  const onChangeWhen = value => {
    setFormData({ ...formData, When: value });
  };

  const onChangeBedsAndBathroom = value => {
    setFormData({ ...formData, ...value });
  };

  const onChangeAddress = value => {
    setFormData({ ...formData, ...value });
  };

  const onChangeUser = value => {
    setFormData({ ...formData, ...value });
  };

  const onFormSubmit = (v) => {
    const data= new FormData()
    data.set('First Name',formData.FirstName)
    data.set('Last Name',formData.LastName)
    data.set('Email', formData.Email)
    data.set('Phone Number', formData.PhoneNumber)
    data.set('Worth',formData.Worth)
    data.set('Sell When',formData.When)
    data.set('Beds', formData.Beds)
    data.set('Bathrooms', formData.Bathrooms)
    data.set('Property Type', formData.Property)
    data.set('Address', formData.Address)
    data.set('City',formData.City)
    data.set('State', formData.State)
    data.set('Zipcode', formData.Zipcode)
    // data.set('Agent', formData.Agent)
    data.set('checkbox', v)

    const requestOptions = {
        method: 'POST',
        body: data
      };
    fetch('https://yesimmo.ch/post.php', requestOptions)
      .catch(err=> console.log(err));
  };

  switch (stepNumber) {
    case 1:
      return <Step1 {...formData} Next={() => add()} changeProperty={v => onChangeProperty(v)} currentValues={formData} />;
    case 2:
      return <Step2 {...formData} Next={() => add()} Prev={() => subtract()} changeWorth={v => onChangeWorth(v)} currentValues={formData}/>;
    case 3:
      return <Step3 {...formData} Next={() => add()} Prev={() => subtract()} changeWhen={v => onChangeWhen(v)} currentValues={formData}/>;
    case 4:
      return (
        <Step4
          {...formData}
          Next={() => add()}
          Prev={() => subtract()}
          changeBedsAndBathroom={v => onChangeBedsAndBathroom(v)}
          currentValues={formData}
        />
      );
    case 5:
      return <Step5 {...formData} Next={() => add()} Prev={() => subtract()} changeAddress={v => onChangeAddress(v)} currentValues={formData}/>;
    case 6:
      return (
        <Step6
          {...formData}
          Prev={() => subtract()}
          changeUser={v => onChangeUser(v)}
          formSubmit={(v) => {onFormSubmit(v); add();}}
          currentValues={formData}
        />
      );
    case 7:
      return (
        <ThankYou/>
      );
    default:
      return <Step1 {...formData} Next={() => add()} changeProperty={v => onChangeProperty(v)} currentValues={formData} />;
  }
}
export default MultiStepForm;
