import React, { useState, useEffect } from 'react';

function Step4({ Next, Prev, changeBedsAndBathroom, currentValues }) {

  useEffect(() => {
    var element = document.getElementById("special");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
  }, []);
  const [Beds, setBeds] = useState("1");
  const [Bathrooms, setBathrooms] = useState("1");

  const [highLightedButton1, setHighLightedButton1] = useState(currentValues.Beds);
  const [highLightedButton2, setHighLightedButton2] = useState(currentValues.Bathrooms);


  const highLightButton1 =(value)=>{
    if(highLightedButton1 === value){
      return "HighlightedNumberButtonStyle"
    }else{
      return "formNumberButtons"
    }
  }
  const highLightButton2 =(value)=>{
    if(highLightedButton2 === value){
      return "HighlightedNumberButtonStyle"
    }else{
      return "formNumberButtons"
    }
  }

  const combinedClick1 =(value)=> {
    setBeds(value)
    setHighLightedButton1(value)
  }
  const combinedClick2 =(value)=> {
    setBathrooms(value)
    setHighLightedButton2(value)
  }
  const combinedNextClick =()=>{
    changeBedsAndBathroom({ Beds, Bathrooms });
    Next()
  }
  return (
    <div className="formContainer">
      <div className="textContainer">
        <h1 className="formTitle">Estimez la Valeur de votre Maison. <br/>Gratuitement et sans Engagement.</h1>
      </div>
      <hr/>
      <div className="formBox" id="special">
        <h2>4. Nombre de Pièces et Salles-de-Bains?</h2>
        <div className="formContent">
          <h4>Pièces</h4>
          <button className={highLightButton1("1")} value="1" onClick={() => combinedClick1('1')}>
            1
          </button>
          <button className={highLightButton1("2")} value="2" onClick={() => combinedClick1('2')}>
            2
          </button>
          <button className={highLightButton1("3")} value="3" onClick={() => combinedClick1('3')}>
            3
          </button>
          <button className={highLightButton1("4")} value="4" onClick={() => combinedClick1('4')}>
            4
          </button>
          <button className={highLightButton1("5+")} value="5+" onClick={() => combinedClick1('5+')}>
            5+
          </button>
          <button className={highLightButton1("Not Sure")} value="Not Sure" onClick={() => combinedClick1('Not Sure')}>
            Pas Sûr
          </button>
          <h4>Salles-de-Bains</h4>
          <button className={highLightButton2("1")} value="1" onClick={() => combinedClick2('1')}>
            1
          </button>
          <button className={highLightButton2("2")} value="2" onClick={() => combinedClick2('2')}>
            2
          </button>
          <button className={highLightButton2("3")} value="3" onClick={() => combinedClick2('3')}>
            3
          </button>
          <button className={highLightButton2("4")} value="4" onClick={() => combinedClick2('4')}>
            4
          </button>
          <button className={highLightButton2("5+")} value="5+" onClick={() => combinedClick2('5+')}>
            5+
          </button>
          <button className={highLightButton2("Not Sure")} value="Not Sure" onClick={() => combinedClick2('Not Sure')}>
            Pas Sûr
          </button>
        </div>
        <div className="containerWidth">
          <div className="buttonContainer">
            <button className="prevButton" onClick={Prev}>
              Précédent
            </button>
            <button className="nextButton" onClick={()=>combinedNextClick()}>
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step4;
