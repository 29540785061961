import React, { useState, useEffect } from 'react';

const User = {
  FirstName: null,
  LastName: null,
  Email: null,
  PhoneNumber: null,
};

function Step6({ Prev, changeUser, formSubmit, currentValues }) {

  useEffect(() => {
    var element = document.getElementById("special");
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "end"});
  }, []);
  const [userData, setUserData] = useState(User);
  const [checkBox, setCheckBox] = useState(false)

  const onChangeUser =(user)=>{
    setUserData(user)
    changeUser(user);
  }

  return (
    <div className="formContainer">
      <div className="textContainer">
        <h1 className="formTitle">Estimez la Valeur de votre Maison. <br/>Gratuitement et sans Engagement.</h1>
      </div>
      <hr/>
      <form className="formBox" onSubmit={(e) => {e.preventDefault();formSubmit(checkBox);}} id="special">
        <h2>6. Vos Coordonnées</h2>
        <div className="formContent">
          <div className="formRow">
            <div className="userInputContainer">    
              <h4>Prénom</h4>
              <input
                className="userInput"
                placeholder="Prénom"
                onChange={e => onChangeUser({ ...userData, FirstName: e.target.value })}
                value={currentValues.FirstName ?  currentValues.FirstName : ""}
                required
              ></input>
            </div>
            <div className="userInputContainer">
              <h4>Nom</h4>
              <input
                className="userInput"
                placeholder="Nom"
                onChange={e => onChangeUser({ ...userData, LastName: e.target.value })}
                value={currentValues.LastName ?  currentValues.LastName : ""}
                required
              ></input>
            </div>
          </div>
          <div className="formRow">
            <div className="userInputContainer">  
              <h4>Téléphone</h4>
              <input
                className="userInput"
                placeholder="Téléphone"
                onChange={e => onChangeUser({ ...userData, PhoneNumber: e.target.value })}
                value={currentValues.PhoneNumber ?  currentValues.PhoneNumber : ""}
                required
              ></input>
            </div>
            <div className="userInputContainer">
              <h4>Adresse E-mail</h4>
              <input
                className="userInput"
                placeholder="Adresse E-mail"
                onChange={e => onChangeUser({ ...userData, Email: e.target.value })}
                value={currentValues.Email ?  currentValues.Email : ""}
                required
              ></input>
            </div>
          </div>
        </div>
        <label htmlFor="checkbox" hidden>I Agree to Terms and Conditions</label>
        <input type="checkbox" name="checkbox" id="checkbox" onChange={()=>setCheckBox(true)} hidden/>
        <br></br>
        <div className="containerWidth">
          <div className="buttonContainer">
            <button className="prevButton" onClick={Prev}>
              Précédent
            </button>
            <input className="nextButton" type="submit" value="Envoyer">
            </input>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Step6;
